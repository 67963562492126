import React, {useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {JsonForms} from '@jsonforms/react';
import {getControlPath} from '@jsonforms/core';
import filter from 'lodash/filter';
import {Interweave} from 'interweave';

import {Block, Container} from 'pages/Main/styles';
import Header from '../../Header/StepperHeader';
import BannerImage from '../../BannerImage';
import FormContext from '../../FormContext/FormContext';
import ProgressBar from '../../ProgressBar';

import {VehiclesProvider} from '../../FormContext/VehiclesContext';
import useEnterClick from 'utils/useEnterClick';
import {StyleContext} from 'pages/Main';
import {StageContext} from '../../Stages';

import {
  Button, 
  Col, 
  Error, 
  Footer, 
  FormWrapper, 
  MarginTopBox, 
  Row, 
  StyledForm, 
  Title, 
  Description, 
  BackButton
} from 'shared/ui';

const getProperty = (stepVariant) => {
  return stepVariant === 'vehicles' ? 'VEHICLE' : 'ASSET';
};
const getTitle = (stepVariant) => {
  return stepVariant === 'vehicles' || stepVariant === 'assets' ? 'Select the asset(s) you want to protect.' : 'Choose your plan';
};
const getButtonText = (stepVariant, checked, property) => {
  const ending = checked?.length > 1 ? 'S' : '';
  return stepVariant === 'vehicles' || stepVariant === 'assets' ? `Confirm (${checked?.length} ${property}${ending})` : 'NEXT';
};
const getClassName = (fullSize, smallSize) => {
  const smSizeClass = smallSize ? 'ix-container smallSize' : 'ix-container';
  return fullSize ? 'ix-container fullSize' : smSizeClass;
};

function AssetsTemplate({
  data,
  setData,
  stepNumber,
  toPrevStep,
  toNextStep,
  schema,
  uiSchema,
  jsonFormCells,
  jsonFormRenderers,
}) {
  const { clientWidth } = useContext(StyleContext);
  const { 
    commonStyles, 
    currentStageNumber,
    clientData: { fullSize, smallSize, isMicroSite, title, button, addButton, heroImage, form, template, progressBar } = {} 
  } = useContext(StageContext);

  const [showValidation, setShowValidation] = useState(false);
  const [allFieldsAreEmpty, setAllFieldsAreEmpty] = useState(true);
  const [errors, setErrors] = useState([]);
  const [additionalErrors, setAdditionalErrors] = useState([]);
  const [checked, setChecked] = useState();
  const [fieldsExpanded, setFieldsExpanded] = useState(false);
  const [schemaKeys, setSchemaKeys] = useState([]);
  
  useEffect(() => {
    let result = [];
    let newData = {...data};
    if (schemaKeys.length) {
      for (const [key, dataValue] of Object.entries(newData)) {
        if (schemaKeys.includes(key)) {
          if (Array.isArray(dataValue)) {
            const filterResult = newData[key].filter((item) => item.checked);
            result = [...result, ...filterResult];
            newData[key] = filterResult;
          }
        }
      }
      setChecked(result);
    }
  }, [data, schemaKeys]);
  
  useEffect(() => {
    const items = uiSchema.elements;
    const result = [];
    if (items.length) {
      for (const item of items) {
        for (const icon of item.elements) {
          if (icon.scope) {
            result.push(icon.scope.replace('#/properties/', ''));
          }
        }
      }
    }
    if (result.length) setSchemaKeys(result);
  }, [uiSchema]);

  const validateCheckedArrayItems = () => {
    const err = [];
    let schema_errors = [...errors];
    for (const [key, dataValue] of Object.entries(data)) {
      if (Array.isArray(dataValue)) {
        data[key].map((item,i) => {
          const instancePath = `/${key}/${i}/`;
          if (!item.checked) {
            for (let i = schema_errors.length - 1; i >= 0; i--) {
              if (schema_errors[i].instancePath.includes(instancePath)) {
                schema_errors.splice(i, 1);
              }
             }
          }
        });
      }
    }
    schema_errors.map((item) => {
      if(!(item.keyword === 'additionalProperties' && item.params.additionalProperty === 'checked')) {
        err.push(item);
      }
    });
    return err;
  };

  const handleSubmit = () => {
    const schema_errors = validateCheckedArrayItems();
    if (schema_errors.length) {
      setShowValidation(true);
    } else {
      setData(data);
      setSchemaKeys([]);
      toNextStep();
    }
  };

  const isArraysExists = useMemo(() => {
    let result = 0;
    for (const [, dataValue] of Object.entries(data)) {
      if (Array.isArray(dataValue) && dataValue.length > 0) {
        result += 1;
      }
    }
    return result;
  }, [data]);

  const handleFormChange = ({ data, errors }) => {
    setData(data);
    setErrors([...errors, ...additionalErrors]);
    setAllFieldsAreEmpty(Object.values(data).every(value => !value));
  };

  const handleUpdate = () => {
    if (errors.length) {
      setShowValidation(true);
    } else {
      setData(data);
      setFieldsExpanded(false);
    }
  };

  useEnterClick(fieldsExpanded ? handleUpdate : handleSubmit, [data, errors, data, toNextStep]);

  const providerValue = useMemo(() => {
    return {data: data, errors, setErrors, showValidation, setAdditionalErrors };
  }, [data, showValidation, errors, setErrors, setAdditionalErrors]);

  const handleAddItem = () => {
    Object.keys(data).map(key => {
      if (Array.isArray(data[key])) {
        let updatedData = data[key];
        updatedData.push({});
        setData({
          ...data,
          [key]: updatedData,
        });
      }
    });
  };

  const handleBack = () => {
    if (setFieldsExpanded && fieldsExpanded) {
        setFieldsExpanded(false);
        return;
    }
    toPrevStep();
  };

  const property = getProperty(template);

  const errorsPerEntry = filter(errors, (error) => {
    if(error.params.additionalProperty !== 'checked') {
      return getControlPath(error);
    }
  });

  const renderTitle = !title || title?.visible || title?.visible === undefined;
  return (
    <Container
      style={commonStyles}
      position={heroImage?.position}
      className={getClassName(fullSize, smallSize)}
    >
      {smallSize && heroImage?.position !== 'contain' ? null :
        <BannerImage heroImage={heroImage} commonStyles={commonStyles} fullSize={fullSize}/>
      }
      <Block width={form?.width} className="ix-step-wrapper">
        <FormWrapper commonStyles={commonStyles}>
          {progressBar?.visible && <ProgressBar />}
          <Header
            stepNumber={stepNumber}
            handleBack={handleBack}
          />
          <div className='ix-content' style={{display: 'flex', flexDirection: 'column', width: form?.width || 'auto'}}>
            <Col>
              {renderTitle &&
                <MarginTopBox className={'ix-title-div'}>
                  <Row justifyContent={'space-between'} >
                    <Title
                      variant={isMicroSite && (clientWidth >= 470) ? 'h3' : 'h5'}
                      className={'ix-title'}
                      textAlign={'left'}
                      color={'#010101'}
                    >
                      <BackButton handleBack={handleBack} backButton={title?.backButton} visible={currentStageNumber !== 1 && title?.backButton?.visible} />
                      <Interweave content={title?.content || getTitle(template)} />
                    </Title>
                    {template === 'vehicles' && <Button
                      variant='text'
                      color='secondary'

                      className='text'
                      onClick={handleAddItem}
                      text={addButton?.content || 'ADD VEHICLE'}
                      flex={addButton?.placement === 'fullwidth'}
                      borderRadius={addButton?.borderRadius}
                    />}
                  </Row>
                </MarginTopBox>
              }
              <Description step={schema.stepName} position='top'/>
              <MarginTopBox className={'ix-form-div'}>
                <StyledForm showWarnings={showValidation && !allFieldsAreEmpty} headingsColor={'#010101'} placeholdersColor={'#b9b9c9'}>
                  <FormContext.Provider value={providerValue}>
                    <VehiclesProvider>
                    <JsonForms
                      schema={schema}
                      uischema={uiSchema}
                      data={data}
                      renderers={jsonFormRenderers}
                      cells={jsonFormCells}
                      validationMode={
                        showValidation ? 'ValidateAndShow' : 'ValidateAndHide'
                      }
                      onChange={handleFormChange}
                    />
                    </VehiclesProvider>
                  </FormContext.Provider>
                  {allFieldsAreEmpty || validateCheckedArrayItems().length ?
                    <Row>
                      <Error
                        errors={errorsPerEntry.length > 0 ? errorsPerEntry : []}
                        showValidation={showValidation}
                        content={'* Fields in red are required.'}
                      />
                    </Row>
                  : null}
                  <Description step={schema.stepName} position='bottom'/>
                  <MarginTopBox fullSizeMarginTop='10px'>
                    <Row justifyContent={['center', 'left', 'right'].includes(button?.placement) ? button?.placement : 'center'}>
                      <Button
                        variant='contained'
                        color='secondary'
                        className='secondary'
                        onClick={handleSubmit}
                        text={button?.content || getButtonText(template, checked, property)}
                        flex={button?.placement === 'fullwidth'}
                        borderRadius={button?.borderRadius}
                        disabled={isArraysExists && !checked?.length}
                      />
                    </Row>
                  </MarginTopBox>
                </StyledForm>
              </MarginTopBox>
            </Col>
          </div>
        </FormWrapper>
        <Footer />
      </Block>
    </Container>
  );
}
AssetsTemplate.propTypes = {
  data: PropTypes.any.isRequired,
  schema: PropTypes.any.isRequired,
  uiSchema: PropTypes.any.isRequired,
  jsonFormCells: PropTypes.any.isRequired,
  jsonFormRenderers: PropTypes.any.isRequired,
  toNextStep: PropTypes.func.isRequired,
  toPrevStep: PropTypes.func.isRequired,
  stepNumber: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
};

export default AssetsTemplate;
